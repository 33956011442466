import type { MaybeRefOrGetter } from 'vue'
import { suffixMetaTitle } from '~/helpers/metadata'

export function usePageView(
  title: MaybeRefOrGetter<string | null | undefined>,
  additionalParams: MaybeRefOrGetter<object | null> = computed(() => {})
) {
  const gtm = useGtm()

  const ssrRenderedIgnored = useState('ssrRendered', () => import.meta.server)

  onMounted(() => {
    if (ssrRenderedIgnored.value) {
      ssrRenderedIgnored.value = false
      return
    }
    const url = new URL(window.location.href)

    for (const key in Object.fromEntries(url.searchParams.entries())) {
      url.searchParams.delete(key)
    }

    let eventSent = false
    watch(
      [computed(() => toRef(title).value), additionalParams],

      ([_title, _addParams]) => {
        if (_title && _addParams !== null && !eventSent) {
          // Add a timeout to make sure that page view is sent after gtm parameters
          setTimeout(() => {
            gtm?.trackEvent({
              event: 'page_view',
              page_location: url.toString(),
              page_title: suffixMetaTitle(_title),
              ..._addParams
            })
          }, 50)
          eventSent = true
        }
      },
      {
        immediate: true
      }
    )
  })
}
